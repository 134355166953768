.card {
    border-radius: 10px;
  }
  
  .card-header {
    border-bottom: none;
  }
  
  .list-group-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
  }
  
  .list-group-item i {
    font-size: 1.5rem;
  }
  