.plans-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .plans-heading {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: #333;
  }
  
  .plans-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
  
  
  .plan-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .plan-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .plan-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .plan-details {
    margin: 10px 0;
    font-size: 1rem;
    color: #555;
  }
  
  .plan-price {
    font-size: 1.25rem;
    color: #000;
    margin-bottom: 20px;
  }
  
  .select-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .select-button:hover {
    background-color: #0056b3;
  }
  
  .selected-plan {
    margin: 20px 0;
    padding: 20px;
    border: 2px dashed #007bff;
    border-radius: 8px;
  }
  
  .selected-plan-title {
    font-size: 1.75rem;
    color: #007bff;
  }
  
  .duration-select {
    margin-top: 20px;
  }
  
  .duration-select-box {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 1rem;
  }
  
  .payment-container {
    margin: 20px 0;
  }
  @media (max-width: 768px) {
    .selected-plan, .plans-container {
      padding: 1rem;
    }
  
    .plan-card {
      padding: 0.5rem;
    }
  }
  .select-button {
    width: 100%;
  }
    